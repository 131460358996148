@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-layout-content {
  padding: 12px 24px !important;
}

.apexcharts-text tspan {
  /* fill: #ddf2fd; */
  /* fill: #000000; */
  fill: #3390be;
}

@media only screen and (max-width: 720px) {
  .ant-picker-panel-container {
    width: 280px !important;
    overflow: scroll !important;
  }
}

.shadow-charts {
  box-shadow: 5px 6px 4px 0px rgba(120, 120, 120, 0.22);
  -webkit-box-shadow: 5px 6px 4px 0px rgba(120, 120, 120, 0.22);
  -moz-box-shadow: 5px 6px 4px 0px rgba(120, 120, 120, 0.22);
}

.hide-scrollbar {
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}
